import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

import "./style.less";

const UnderConstruction = () => {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "under-construction.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <div className="under-construction">
      <h1 className="under-construction__heading">Under Konstruksjon</h1>
      <Img className="under-construction__image" fluid={data.image.childImageSharp.fluid} />
    </div>
  );
};

export default UnderConstruction;

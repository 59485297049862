import React from "react";
import { graphql } from "gatsby";
import UnderConstruction from "../components/UnderConstruction";
import SEO from "../components/seo";
import Page from "../components/Page";

interface Props {
  data: {
    site: {
      siteMetadata: {
        isUnderConstruction: boolean;
      };
    };
  };
}

const IndexPage = ({ data }: Props) => {
  if (data.site.siteMetadata.isUnderConstruction) {
    return (
      <>
        <SEO title="Nyevo" description="" />
        <UnderConstruction />
      </>
    );
  }

  return <Page>Velkommen til Nyevo!</Page>;
};

export default IndexPage;

export const query = graphql`
  query {
    site {
      siteMetadata {
        isUnderConstruction
      }
    }
  }
`;
